import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const PageContainer = styled.div`
  margin: 80px 0;
  .pageWrapper {
    font-family: "Kanit", sans-serif;
    padding-right: 0;
    .title {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 30px;
      text-align: center;
    }
    .contentWrapper {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      p.intro {
        margin-bottom: 35px;
      }
      p.paddingLeft {
        padding-left: 15px;
      }
      h3.header {
        font-weight: 400;
        font-size: 22px;
        color: #181818;
        margin-bottom: 15px;
      }
      ul.icon {
        padding-left: 30px;
        li {
          font-weight: 400;
          font-size: 16px;
          color: #646464;
          .hilight {
            color: #181818;
            padding-right: 10px;
          }
          &::marker {
            color: #7497b2;
          }
        }
      }
    }
  }
`

const PrivacyPolicyPage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    pageTitle,
    pageContent,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "navMenu7" })}
      />
      <PageContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <div className="pageWrapper">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: pageTitle,
                  }}
                />
                <div
                  className="contentWrapper"
                  dangerouslySetInnerHTML={{
                    __html: pageContent.pageContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PrivacyPolicyPageQuery($locale: String) {
    pageFields: allContentfulPrivacyPolicyPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          pageTitle
          pageContent {
            pageContent
          }
        }
      }
    }
  }
`

export default injectIntl(PrivacyPolicyPage)
